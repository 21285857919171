//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch, computed, reactive } from '@nuxtjs/composition-api';
export default {
  name: 'MegaMenuMobile',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    tabActive: {
      type: String,
      default: ''
    },
    categories: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const TIME_TO_OPEN_MENU = 200;
    const TIME_TO_CHANGE_LEVEL1 = 140;
    const megaMenuOpen = ref(props.isOpen);
    const activeTab = ref(props.tabActive);
    const categoryVisible = ref([]);
    const levelOneShown = reactive({});
    let showMegaMenu;
    const showMegaMenuLevelTwo = false;

    const activeTabType = computed(() => {
      const TAB_TYPES = {
        producten: 'default',
        merken: 'default',
        service: 'default',
        inspiratie: 'default'
      };

      return TAB_TYPES[activeTab.value] || 'default';
    });

    watch(
      () => props.isOpen,
      (newVal) => {
        if (newVal) {
          showMegaMenu = setTimeout(() => {
            megaMenuOpen.value = newVal;
          }, TIME_TO_OPEN_MENU);
        } else {
          clearTimeout(showMegaMenu);
          megaMenuOpen.value = newVal;
        }
      }
    );
    watch(
      () => props.tabActive,
      (newVal) => {
        if (Object.keys(props.categories).length === 0) {
          emit('restartActiveTab');
          return;
        }
        activeTab.value = newVal;
        if (!activeTab.value) {
          return;
        }
        for (const category in props.categories) {
          if (!category.toLowerCase().indexOf(activeTab.value)) {
            categoryVisible.value = props.categories[category];
          }
        }
      }
    );

    return {
      activeTab,
      activeTabType,
      levelOneShown,
      megaMenuOpen,
      categoryVisible,
      showMegaMenuLevelTwo,
      TIME_TO_CHANGE_LEVEL1
    };
  },

  methods: {
    hideLevelOne() {
      this.megaMenuOpen = false;
      this.showMegaMenuLevelTwo = false;
      this.$emit('restartActiveTab');
    },
    hideLevelTwo() {
      this.showMegaMenuLevelTwo = false;
    },

    closeMenu() {
      this.$emit('onClose');
    },
    levelOneChange(val) {
      this.showMegaMenuLevelTwo = false;
      if (val) {
        this.temporalHover = setTimeout(() => {
          this.levelOneShown = val;
          this.showMegaMenuLevelTwo = true;
        }, this.TIME_TO_CHANGE_LEVEL1);
      } else {
        clearTimeout(this.temporalHover);
      }
    }
  }
};
