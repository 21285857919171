import { render, staticRenderFns } from "./Tab.vue?vue&type=template&id=9b33d53a&scoped=true&"
import script from "./Tab.vue?vue&type=script&lang=ts&"
export * from "./Tab.vue?vue&type=script&lang=ts&"
import style0 from "./Tab.vue?vue&type=style&index=0&id=9b33d53a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b33d53a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsChevronUpIcon24px: require('/var/www/packages/theme/components/icons/chevron-up/ChevronUpIcon24px.vue').default,IconsChevronDownIcon24px: require('/var/www/packages/theme/components/icons/chevron-down/ChevronDownIcon24px.vue').default,IconsChevronRight16px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight16px.vue').default})
