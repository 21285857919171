//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default {
  name: 'MegaMenuLevelOne',
  props: {
    categoryVisible: {
      default: () => [],
      type: Array
    },
    categoryActive: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup(_, { emit }) {
    const formatUrl = useUrlFormatter();

    const isHovered = ref(false);

    const handleHover = (value) => {
      isHovered.value = Boolean(value);
      emit('onLevelOneChange', value);
    };

    return {
      formatUrl,
      handleHover,
      isHovered
    };
  }
};
